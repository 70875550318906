const __xjIndex = {
    handleClickTab() {
        var $this = $(this),
            idx = $this.index(),
            {id, type} = $this.data(),
            $parents = $this.parents('.w');

        if (id) {
            $parents.find('._ecologyMore').attr('href', `/org/index?classify_id=${id}`);
        }

        if (type) {
            $parents.find('._ecologyMore').attr('href', `/news/index?type=${type}`);
        }

        $this.addClass('active').siblings().removeClass();
        $parents.find('._list ul').addClass('hide').eq(idx).removeClass('hide');
    },
    handleClickQuestion() {
        var idx = $(this).index(),
            type = $(this).attr('data-type');

        $('._questionMore').attr('href', `/needs/index?type=${type}&hub_province=650000`);

        $(this).addClass('active').siblings().removeClass();
        $('._questionCont .list').addClass('hide').eq(idx).removeClass("hide");
    },
    handleClickSearchBtn() {
        var keywords = $('._keyword').val();

        if (keywords == '') {
            layer.msg('请输入相关搜索关键词');
            return false;
        }
        window.open('//www.kczg.org.cn/search/article?from=xj&keyword=' + keywords);
    },

    init() {
        new Swiper('._part1Swiper', {
            loop: true,
            paginationClickable: true,
            pagination: '.service-page',
            autoplay: 3000
        });
        $("._list list").hide().eq(0).removeClass('hide');

    }
}
$(function () {
    __xjIndex.init();
    $(document)
        .on("click", "._tabs span", __xjIndex.handleClickTab)
        .on("click", "._questionTab span", __xjIndex.handleClickQuestion)
        .on("click", "._searchBtn", __xjIndex.handleClickSearchBtn)
})
